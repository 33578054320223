/*=================================================================*/
/*                     BOOTSTRAP ELEMENTS
/*=================================================================*/
.breadcrumb {
    font-size: 14px;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
}

.breadcrumb>.active {
    color: #9c9c9c;
}

/*=================================================================*/
/*                      THEME ELEMENTS
/*=================================================================*/

.rounded {
    border-radius: 8px !important;
}

.bg-white {
    background: #FFF;
}

.shadow-dark {
    -webkit-box-shadow: 0px 5px 20px 0px rgba(69,67,96,0.1);
    -moz-box-shadow: 0px 5px 20px 0px rgba(69,67,96,0.1);
    box-shadow: 0px 5px 20px 0px rgba(69,67,96,0.1);
}

.shadow-light {
    -webkit-box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
    -moz-box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
    box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
}

.shadow-blue {
    -webkit-box-shadow: 0px 5px 20px 0px rgba(108,108,229,0.5);
    -moz-box-shadow: 0px 5px 20px 0px rgba(108,108,229,0.5);
    box-shadow: 0px 5px 20px 0px rgba(108,108,229,0.5);
}

.shadow-pink {
    -webkit-box-shadow: 0px 5px 20px 0px rgba(249,123,139,0.5);
    -moz-box-shadow: 0px 5px 20px 0px rgba(249,123,139,0.5);
    box-shadow: 0px 5px 20px 0px rgba(249,123,139,0.5);
}

.shadow-yellow {
    -webkit-box-shadow: 0px 5px 20px 0px rgba(249,215,76,0.5);
    -moz-box-shadow: 0px 5px 20px 0px rgba(249,215,76,0.5);
    box-shadow: 0px 5px 20px 0px rgba(249,215,76,0.5);
}

.padding-30 {
    padding: 30px;
}

.triangle-left {
    &:before {
        content: "";
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 15px solid #FFF;
        position: absolute;
        left: -15px;
        top: 20%;
    }
}

.triangle-top {
    &:before {
        content: '';
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #FFF;
        position: absolute;
        left: 50%;
        top: -10px;
        transform: translateX(-7.5px)
    }
}

.morphext > .animated {
    display: inline-block;
}

.text-light {
    h1, h2, h3, h4, h5, h6 {
        color: #FFF;
    }
}
