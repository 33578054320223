
/*=================================================================*/
/*                      BASE
/*=================================================================*/
* {
    margin: 0;
    padding: 0;
}

html {
    margin: 0 !important;
    overflow: auto !important;
    scroll-behavior: smooth;
}

body {
    color: #5E5C7F;
    background-color: #F9F9FF;
    font-family: $fontstack;
    font-size: 16px;
    line-height: 1.7;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    max-width: 100%;
    height: auto;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.relative {
    position: relative;
}

/*=================================================================*/
/*                      TYPOGRAPHY
/*=================================================================*/
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #454360;
    font-family: $fonthead;
    font-weight: 700;
    margin: 20px 0;
    padding: 0 !important;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 24px;
    margin:0 !important;
}

h4 {
    font-size: 22px;
    color:#555455;
    font-weight:400;
    letter-spacing: 1px;

    padding: 15px;
}

a {
    color: $colorlink;
    outline: 0;
    @include transition(.3s);

    &:hover {
        color: $colorblue;
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }
}

a.main:link{
  
  text-decoration: none;
  left: 0;
  border-bottom: 1px solid $colorblue;
}



a.main:hover {
  color: $colorblue;
  border-bottom: 2px solid $coloryellow;
}

blockquote {
    padding: 30px 20px;
    margin: 0 0 30px;
    font-size: 24px;
    background: #F7F7F7;
    border-radius: 10px;
    font-style:italic;
    border-left:8px solid $colorblue;
    line-height:1.6;
    position: relative;
}

blockquote::before{
font-family:Arial;
  color: $colorblue;
  font-size:4em;
  position: absolute;
  left: 2px;
  top:-20px;
}


blockquote::after{
  content: '';
}

blockquote p {
    line-height: 1.6;
}

/* === Pre === */
pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 0;
}

.colorpink {
    color: $colorpink;
}

.pointer {
    cursor: pointer;
}

.p-30 {
    padding: 30px;
}

hr {
    margin-top: 40px;
}

.rightalign {
  display: flex;
  justify-content: flex-end;
}
