/*=================================================================*/
/*                      HEADER                              
/*=================================================================*/


.header {
	background: $colorbackground;
	padding: 20px 0;
	border-bottom: solid 1px rgba(0, 0, 0, 0.1);


	.navbar {
		padding: 0;
		float:right;
		
	}

	.navbar li{
	line-height: 10px;
	}

	.navbar-brand {
	   color: $colortext;
		padding-top: 0;
		padding-bottom: 0;
		float: left;
	}

	.navbar-dark .navbar-nav .nav-link {
		color: $colortext;
	}

	.nav-link {
		font-size: 16px;
		font-weight: 700;
		padding: 0;
		cursor: pointer;

		&.active {
			color: $colorblue !important;
		}

		&:hover {
			color: $colorblue !important;
		}
	}

	.dropdown-menu {

		position: absolute;
		top: 100%;
		left: 0;
		min-width: 10rem;
		padding: .5rem 0;
		margin: .125rem 0 0;
		font-size: 1rem;
		color: $colortext;
		text-align: right;
		list-style: none;
		background-color: $colortext;
		background-clip: padding-box;
		border: 0;
		border-radius: .25rem;
		@extend .shadow-dark;
		
	}

	button.navbar-toggler:focus {
		outline: 0;
		outline: 0;
	}

	.navbar-nav li {
		padding-left: 3rem;
		padding-bottom: .5rem;
		text-align: right !important;
	}

	@media (min-width: 992px) {
		.navbar-expand-lg .nav-link .navbar-nav li {
			
			 
		}
	}


		.navbar-toggler-icon {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
	
		}

		.navbar-toggler {
			border-color: rgba(#000000, 0.5);
			margin-bottom: 1.5rem;
			
		
		}
	
}