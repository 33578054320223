/*=================================================================*/
/*                      SECTIONS
/*=================================================================*/


main.content {
}



section {
  padding-top: 110px;
  position: relative;
}

ul {
 list-style-position: outside;
}
li{
  margin: 10px 0;
  line-height:20px;
  }

.number {
  margin: 0;
  padding: 0;
  list-style-type:none;
}

.number li {
  margin: 2em 0;
  counter-increment:step-counter;

  font-size: 20px;
  line-height:29px;

}

.number li::before{
  content: counter(step-counter);
  margin-right: 20px;
  font-size: 24px;
  background-color: $colorblue;
  color: white;
  font-weight: bold;
  padding: 6px 15px;
  border-radius: 10px;
}

section.home {
  background: $colorbackground;
  padding: 0;
  height: 100vh;
  min-height: 100vh;

  .intro {
    margin: auto;
    max-width: 540px;
    text-align: center;
    position: relative;
    z-index: 1;

    h1 {
      color: $colortext;
      font-size: 36px;
    }

    p {
      color: $colortext;
      font-size: 18px;
      margin-bottom: 0;
    }

    span {
      display: inline-block;

      label {
        margin-bottom: 0;

        &::after {
          content: attr(value);
        }
      }
    }
  }



}

.section-title {
  font-size: 36px;
  margin: 0;
  position: relative;

  &:before {
    content: "";

    display: block;
    height: 37px;
    left: -14px;
    top: -14px;
    position: absolute;
    width: 37px;
  }
}

.section-space {
  margin-bottom: 20px !important;
}


.label{
      color: #9e9e9e;
      font-size: 14px;
      margin-bottom: 0;
}

.label-wrapper{
  margin:10px;
}

.section-wrapper{
  padding:16px;
  margin-top:40px;
}

.green li{
  list-style: none;
  color: $colorgreen;
  font-size: 20px;
  font-weight: 500;
}

.green ul{
  list-style: none;
}



.section-image-pad{
  display:block;
  padding-top:60px;
  padding-bottom:90px;
  margin-left: auto;
  margin-right: auto;
}


.opacityset {
  opacity: 0.8;
}


/*=================================================================*/
/*                 Portfolio / Works / Blog like items
/*=================================================================*/



.blog-item {
  overflow: hidden;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  @include transition(0.3s);
    margin-bottom: 30px;

  &:hover {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(-10px);
  }

  .thumb {
    position: relative;
    overflow: hidden;
    max-height:280px;


    img {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      @include transition(0.3s);
    }

    &:hover {
      img {
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }

  .category {
    background: $colorblue;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: #fff;
    font-size: 14px;
    padding: 2px 8px;
    position: absolute;
    left: 20px;
    top: 0;
    z-index: 1;
  }

  .details {
    padding: 20px;

    h4.title {
      font-size: 20px;

      a {
        color: $colortext;
        text-decoration: none;

        &:hover {
          color: $colorblue;
        }
      }
    }
  }

  .meta {
    font-size: 14px;
    color: #8b88b1;


    li:not(:last-child) {
      margin-right: 0.6rem;

    }

    li {
      &:after {
        content: "";
        background: #8b88b1;
        border-radius: 50%;
        display: inline-block;
        height: 3px;
        margin-left: 0.6rem;
        vertical-align: middle;
        width: 3px;
      }

      &:last-child::after {
        display: none;
      }
    }
  }
}

.blog-page-section {
  background-color: #fff;
  padding-top: 0;



  .blog-text-title {
    h4 {
      font-size: 30px;

      a {
        color: $colortext;

        &:hover {
          color: #ff4c60;
        }
      }
    }
  }


  .blog-standard {
  }

  .blog-standard .meta {
    font-size: 14px;
    color: #8b88b1;
  }

  .blog-standard .meta li:after {
    content: "";
    background: #8b88b1;
    border-radius: 50%;
    display: inline-block;
    height: 3px;
    margin-left: 0.6rem;
    margin-bottom: 80px;
    vertical-align: middle;
    width: 3px;
  }

  .blog-standard .meta li {
    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  .blog-standard .meta li:not(:last-child) {
    margin-right: 0.6rem;
  }

  .blog-standard .meta li a {
    color: #353353;

    &:hover {
      color: #ff4c60;
    }
  }

  .br-more {
    a {
      border-radius: 60px;
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      font-weight: 700;
      overflow: hidden;
      line-height: 1;
      padding: 12px 32px;
      color: #fff;
      background: #ff4c60;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      text-decoration: none;
  

      &:hover {
        color: #fff;
        -webkit-animation-name: button-push;
        animation-name: button-push;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
      }
    }
  }


  .blog-single {
    background: #fff;
    padding: 30px;
    border-radius: 20px;

    img {
      width: 100%;
    }
  }


}



.spacer {
  clear: both;

  &[data-height="60"] {
    height: 60px;
  }

  &[data-height="70"] {
    height: 70px;
  }

  &[data-height="30"] {
    height: 30px;
  }

  &[data-height="96"] {
    height: 96px;
  }

  &[data-height="20"] {
    height: 20px;
  }
}

.text-link {
  font-family: $fonthead;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

#infscr-loading {
  position: absolute;
  left: 50%;
  margin-left: -15px;
  bottom: 10px;
}

#infscr-loading img {
  display: none;
}

.fa-spinner {
  margin-right: 10px;
  display: none;
}

.scroll-down {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  z-index: 1;

  button {
    background: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
  }
    span {
      color: $colortext;
    }

    .mouse {
      border-color: $colortext;

      .wheel {
        background: $colortext;
      }
    }

}

@-webkit-keyframes ani-mouse {
  0% {
    top: 29%;
  }

  15% {
    top: 50%;
  }

  50% {
    top: 50%;
  }

  100% {
    top: 29%;
  }
}

@-moz-keyframes ani-mouse {
  0% {
    top: 29%;
  }

  15% {
    top: 50%;
  }

  50% {
    top: 50%;
  }

  100% {
    top: 29%;
  }
}

@keyframes ani-mouse {
  0% {
    top: 29%;
  }

  15% {
    top: 50%;
  }

  50% {
    top: 50%;
  }

  100% {
    top: 29%;
  }
}

.mouse-wrapper {
  color: $colortext;
  font-size: 14px;
  display: block;
  max-width: 100px;
  margin: auto;
  text-align: center;
  cursor: pointer;

  span {
    color: $colortext;
  }

  &:hover {
    color: $colortext;
  }
}

.mouse {
  border: solid 2px #fff;
  border-radius: 16px;
  display: block;
  margin: auto;
  margin-top: 10px;
  height: 26px;
  position: relative;
  width: 20px;

  .wheel {
    background: #fff;
    border-radius: 100%;
    display: block;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    height: 4px;
    width: 4px;
    -webkit-animation: ani-mouse 2s linear infinite;
    -moz-animation: ani-mouse 2s linear infinite;
    animation: ani-mouse 2s linear infinite;
  }
}

.circle {
  border-radius: 100%;
}

footer.footer {
  background: $colorbackground;
  padding: 40px 0;
  text-align: center;

  .copyright {
    color: #9c9ab3;
    font-size: 14px;
  }

  &.light {
    background: #f9f9ff;
    border-top: solid 1px rgba(0, 0, 0, 0.05);
  }
}

.iconleft{
  float:left;
  padding: 10px;
}

.aligncenter{
     display:inline-flex;
     align-items:center;
}

.sm-pad {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom:60px;

}
