$colorbackground: #FAF9FF;
$colordefault:#FAF9FF;
$colortext: #333;
$coloryellow: #FFD15C;
$colorlink: #5E5C7F;
$colorpink: #FF4C60;
$colorblue: #0E7EF8;
$colorgreen: #04AA7F;
$fonthead: 'Rubik', sans-serif;
$fontstack: 'Rubik', sans-serif;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
    -moz-transition: all $second ease-in-out;
        transition: all $second ease-in-out;
}
